body,h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6,p,input,textarea,select,label,.navbar,.brand,.btn,button,ul,ol,li,span:not(.fa),table,.btn-simple,.alert,a,.td-name,td,button.close,pre{font-family:"Roboto",sans-serif !important}
p,h1,h2,h3,h4,h5,h6,pre{margin:0}h3,.h3{font-size:1.35rem;font-weight:500}h5,h6{text-transform:none}
.card-header{border-bottom:0}.hide{display:none}.footer .copyright{float:none !important}
.custom-container{max-width:1400px;text-align:center;padding-top:50px}.custom-container .top-row .title h3{margin-bottom:30px;font-size:40px;color:#625b71}
.menu-page-forms-container,.payment-setup-fields-container{max-width:520px}.wrapper{background:#fdffff}
.main-panel{background:0;width:calc(100% - 260px)}.text-danger,.text-danger:hover{color:#e4437d !important}
.text-success,.text-success:hover{color:#87c860 !important}.text-warning,.text-warning:hover{color:#f07c3c !important}
.text-purple{color:#735cb0 !important}.close-popup-container{position:fixed;top:0;left:0;right:0;bottom:0}
.main-panel .content{padding:0 15px}.sidebar .logo:before,.bootstrap-navbar .logo:before,.navbar:before{content:"";position:absolute;bottom:0;right:15px;width:calc(100% - 30px);height:1px;background-color:#625b71}
.modal-backdrop,.modal{z-index:9999}.highest-zindex div{z-index:10000 !important}
.tooltip{z-index:10000}.indeterminate-checkbox{margin:5px}@media screen and (max-width:991px){.main-panel,.main-panel .navbar{width:100%}
}.badge-container{display:flex;align-items:center;flex-wrap:wrap}.badge-container .card-title{display:inline-block;margin-right:10px}
.badge-container .badge{padding:5px 10px;border-radius:8px;margin:0 10px 10px 0}.badge-container.clickable .badge{cursor:pointer}
.badge-container .badge:first-child{margin-left:0}.badge-container .badge-primary{background:transparent;border:2px solid #735cb0;color:#001e31}
.badge-container .badge-secondary{background:#e9ddff;border:2px solid #e9ddff;color:#22005d}
.badge-container .badge-label.inline{margin-right:5px}.badge-container .badge-light{border:1px solid #e3e3e3}
.btn-success{border:2px solid #0093d7;color:#fff;background-color:#0093d7}.btn-danger{border:2px solid #e4437d;color:#fff;outline:0;background-color:#e4437d}
.btn-danger:hover{opacity:.8;background-color:#e4437d}.btn-success:hover,.btn-success:focus,.btn-success:active,.btn-success.active,.open>.btn-success.dropdown-toggle,.btn-success:not(:disabled):not(.disabled):active,.show>.btn-success.dropdown-toggle,.btn-success:not(:disabled):not(.disabled):active:focus{border:2px solid #0093d7;color:#fff;background-color:#0093d7;box-shadow:none;opacity:.8}
.btn-primary{border:2px solid #0093d7;color:#fff;background-color:#0093d7}.btn-neutral,.btn-neutral:hover{color:#625b71;border:2px solid #625b71}
.btn-primary:hover,.btn-primary:focus,.btn-primary:active,.btn-primary.active,.open>.btn-primary.dropdown-toggle,.btn-primary:not(:disabled):not(.disabled):active,.show>.btn-primary.dropdown-toggle,.btn-primary:not(:disabled):not(.disabled):active:focus{border:2px solid #0093d7;color:#fff;background-color:#0093d7;box-shadow:none;opacity:.8}
.btn-default{border:2px solid #625b71;color:#625b71;background-color:#fff}.btn-transparent{background:transparent;color:#50407b}
.btn-default:hover,.btn-default:focus,.btn-default:active,.btn-default.active,.open>.btn-default.dropdown-toggle,.btn-default:not(:disabled):not(.disabled):active,.show>.btn-default.dropdown-toggle,.btn-default:not(:disabled):not(.disabled):active:focus{border:2px solid #625b71;color:#625b71;background-color:#fff;box-shadow:none;opacity:.8}
.btn-flat,.btn-flat:active,.btn-flat:focus,.btn-flat.btn.dropdown-toggle{background-color:#bababa;color:white}
.btn-flat:hover,.btn-flat:active:hover,.btn-flat:focus:hover,.btn-flat.btn.dropdown-toggle:hover{background-color:#bababa;color:white}
.btn .nc-simple-add,.btn .nc-stre-left,.btn .icon-add{margin-right:10px;color:#0093d7}
.btn .nc-simple-add .nc-simple-add:before,.btn .nc-simple-add .nc-stre-left:before,.btn .nc-stre-left .nc-simple-add:before,.btn .nc-stre-left .nc-stre-left:before,.btn .icon-add .nc-simple-add:before,.btn .icon-add .nc-stre-left:before{font-weight:900}
.btn-link .icon-cont{margin-right:5px;margin-bottom:2px}.navbar-minimize .btn i{margin:unset;font-weight:900}
.btn-hidden{visibility:hidden;pointer-events:none;z-index:-1}.btn-group{background:#f1eef7;padding:3px;box-sizing:border-box;border-radius:4px}
.btn-group .btn{border:0;border-radius:4px !important;margin:0 2px;font-size:12px}
.btn-group .btn.active,.btn-group .btn:hover{background:#735cb0}.sweet-alert .btn-success{border:2px solid #0093d7 !important;color:#fff !important;background-color:#0093d7 !important;box-shadow:none !important}
.sweet-alert .btn{padding:.375rem .75rem !important;font-size:1rem !important;margin:0 25px}
.card{background:#FFF;border:0;overflow:hidden}.menu-card{background:#f9fafe;border:1px solid #e3e3e3;max-width:500px;padding:20px}
.date-card{overflow:visible}.menu-card .card-body{padding:0}.card.card-login{background:#fff;border:1px solid #e5e5e5;box-shadow:none;border-radius:4px}
.card-header{background:0}.card-login.card h3{padding-left:0}.card .actions{margin-right:10px}
.card .card{border:0}.card .card .card-body{padding:0}.card .actions{text-align:right;position:absolute;right:0;bottom:0;background:#f9fafe;z-index:2}
.card.custom-card{text-align:center;border-radius:18.5px;overflow:hidden}.card.custom-card.clickable{cursor:pointer;transition:.2s all}
.card.custom-card.clickable:hover{background:#f1f1f1}.card .custom-card-id{font-weight:400;font-size:18px;background:#04a2b2;color:white;border-bottom:1px solid #f6f6f6;padding:10px}
.card .custom-card-content{padding:20px 10px;display:flex;align-items:center;justify-content:center;flex-direction:column}
.card .custom-card-name{margin-bottom:10px}.card .card-title,.card-title{color:#625b71;font-weight:500;margin-bottom:15px}
.card .card-subtitle,.card-subtitle{color:#888ba1;font-size:14px;font-weight:500}
.card .row{transition:.2s all}.card .row.hidden{transform:translateY(200px)}.card:hover .row.hidden{transform:translateY(0)}
.select-card-container .card{transition:.2s all;cursor:pointer}.select-card-container .card:hover{background:#f1f1f1}
.dynamic-menu-card{position:relative;display:flex;flex-direction:column;cursor:pointer;padding:20px;background:#fdffff;box-shadow:0 1px 2px rgba(0,0,0,0.3),0 2px 6px rgba(0,0,0,0.15);border-radius:10px;margin:25px;width:500px;overflow:hidden;font-weight:400;color:#625b71;transition:.2s all}
.dynamic-menu-card:hover{background:#ccecfb}@media all and (min-width:300px){.dynamic-menu-card{width:calc(100% - 50px)}
}@media all and (min-width:1200px){.dynamic-menu-card{width:calc(100% / 2 - 50px)}
}@media all and (min-width:1600px){.dynamic-menu-card{width:calc(100% / 3 - 50px)}
}@media all and (min-width:2000px){.dynamic-menu-card{width:calc(100% / 4 - 50px)}
}.dynamic-menu-card .row{margin:5px 0}.dynamic-menu-card .row:first-child{margin-top:0}
.dynamic-menu-card .row:last-child{margin-bottom:0}.dynamic-menu-card .created-date{margin-left:auto}
.dynamic-menu-card .img-container{width:100%;height:170px;overflow:hidden;display:flex;align-items:center;border-radius:8px}
.dynamic-menu-card .img-container img{width:100%}.dynamic-menu-card .no-photo-container{width:100%;text-align:center}
.dynamic-menu-card .title-row{color:#343a40;flex-wrap:nowrap}.dynamic-menu-card .title-row h3{max-width:80%;overflow-wrap:break-word}
.dynamic-menu-card .description-row{-webkit-line-clamp:3;display:-webkit-box;-webkit-box-orient:vertical;overflow:hidden}
.dynamic-menu-card .actions-row{flex-grow:1;display:flex;align-items:end}.dynamic-menu-card .actions-row .btn{margin:0 10px 0 0}
.dynamic-menu-card .actions-row .btn:last-child{margin-right:0}.dynamic-menu-card .label{font-weight:500}
.dynamic-menu-card .value{font-weight:400}.dynamic-menu-card .value .color-value-inner{width:20px;height:20px;border-radius:4px;border:1px solid #b4b4b4}
.welcome-card{border-radius:10px;overflow:visible;background:#ccecfb;padding:0 30px}
.welcome-card .text-col{display:flex;flex-direction:column;align-items:baseline;justify-content:center;text-align:left}
.welcome-card h2{color:#735cb0;font-weight:500;font-size:25px;white-space:nowrap;margin-bottom:15px}
.welcome-card p{color:#625b71;font-weight:400}.welcome-card .danger,.welcome-card .danger h2,.welcome-card .danger p{color:#e4437d;font-weight:500}
.welcome-card p .role{font-weight:700}.welcome-card img{width:150px;position:relative}
.payment-card{padding:30px;background:#fdffff;box-shadow:0 2px 80px rgba(0,0,0,0.1);border-radius:20px;text-align:center;font-size:16px;font-weight:400;color:#343a40;width:400px;max-width:400px;position:absolute;top:50%;right:50%;transform:translate(50%,-50%)}
.payment-card .text{margin:15px 0 0 0}.payment-card .icon{margin-bottom:10px;margin-top:5px}
.footer.login-footer{background:#f9fafe}.footer.login-footer a{color:#625b71}.main-panel footer,.sliding-panel footer{position:fixed;left:0;bottom:0;width:100%;height:70px;background:white;border-radius:4px;color:black;text-align:center;display:flex;align-items:center;justify-content:flex-end;z-index:1;padding-right:30px;padding-bottom:20px;padding-top:20px;border-top:1px solid #e7e7e7;border-radius:0}
.main-panel footer button,.sliding-panel footer button{margin-right:40px;box-shadow:0 2px 4px rgba(0,0,0,0.2);font-weight:700;margin-bottom:0}
.main-panel footer button:last-child,.sliding-panel footer button:last-child{margin-right:0}
.form-group input,.form-group textarea,.form-group select,.form-group .select__control,.form-group .select__menu,.form-group .react-select,.form-group .react-datetime-picker,.form-group .upload-file-container{max-width:450px}
.form-group input.has-errors,.form-group input.has-errors .react-select__control,.form-group input.has-errors .react-datetime-picker__wrapper,.form-group textarea.has-errors,.form-group textarea.has-errors .react-select__control,.form-group textarea.has-errors .react-datetime-picker__wrapper,.form-group select.has-errors,.form-group select.has-errors .react-select__control,.form-group select.has-errors .react-datetime-picker__wrapper,.form-group .select__control.has-errors,.form-group .select__control.has-errors .react-select__control,.form-group .select__control.has-errors .react-datetime-picker__wrapper,.form-group .select__menu.has-errors,.form-group .select__menu.has-errors .react-select__control,.form-group .select__menu.has-errors .react-datetime-picker__wrapper,.form-group .react-select.has-errors,.form-group .react-select.has-errors .react-select__control,.form-group .react-select.has-errors .react-datetime-picker__wrapper,.form-group .react-datetime-picker.has-errors,.form-group .react-datetime-picker.has-errors .react-select__control,.form-group .react-datetime-picker.has-errors .react-datetime-picker__wrapper,.form-group .upload-file-container.has-errors,.form-group .upload-file-container.has-errors .react-select__control,.form-group .upload-file-container.has-errors .react-datetime-picker__wrapper{border-color:#e4437d}
.form-group{max-width:450px;margin-bottom:1.5rem}.form-group.disabled{cursor:not-allowed;pointer-events:none;z-index:-1}
.form-group.smaller input,.form-group.smaller .error-message{max-width:365px;display:inline-block}
.form-group .form-check .form-check-sign::after,.form-group .form-check .form-check-sign::before,.form-group .form-check .form-check-sign::after{color:#0093d7}
.form-group .form-check .form-check-sign::before,.form-group .form-check .form-check-sign::after{font-size:22px;margin-top:0}
.form-check input[type="checkbox"],.form-check-radio input[type="radio"]{position:static}
.form-group .form-control,.form-group textarea.textarea-input,.form-group.text-area-form-group.with-autosize textarea,.form-group .react-datetime-picker__wrapper,.form-group.text-area-form-group.with-autosize textarea{outline:0;background:#fdffff;color:#343a40;border:0;border:1px solid #625b71;border-radius:4px;transition:none;min-height:40px;padding-left:12px;width:100%}
.form-group .react-datetime-picker__calendar{z-index:9}.form-group .form-control:focus,.form-group .form-control.active,.form-group.text-area-form-group.with-autosize textarea:focus,.form-group.text-area-form-group.with-autosize textarea.active{background:#fdffff;box-shadow:0 0 0 1px #735cb0}
.form-group .disabled,.form-group .react-select.disabled .react-select__control{background:#fdffff;color:#979aae;cursor:not-allowed;pointer-events:none}
.form-group.disabled .form-label,.form-group.disabled .switch-label,.form-group.disabled .upload-text-and-btn,.form-group.disabled .form-check .form-check-sign::before,.form-group.disabled .form-check .form-check-sign::after{color:#c7cadc}
.form-group.disabled .custom-switch .custom-control-label::before,.form-group.disabled .upload-file-container{border-color:#c7cadc}
.form-group.disabled .custom-switch .custom-control-label::after{background-color:#c7cadc}
.form-group.disabled .form-control,.form-group.disabled .react-select .react-select__control,.form-group.disabled.text-area-form-group.with-autosize textarea,.form-group.disabled .react-datetime-picker__wrapper{border-color:#c7cadc;color:#888ba1}
.form-group.disabled .react-select.primary .react-select__control .react-select__value-container--has-value .react-select__single-value,.form-group.disabled .form-check-label,.form-group.disabled .react-datetime-picker__inputGroup input{color:#888ba1}
.form-group.disabled .react-tagsinput-tag.tag-azure{color:#c7cadc;border-color:#c7cadc}
.form-group.disabled .react-datetime-picker__button .react-datetime-picker__button__icon{stroke:#c7cadc}
.form-group.disabled .upload-file-container .upload-image-icon .icon-upload-image path{fill:#c7cadc}
.form-group .form-control::placeholder,.form-group .react-select .react-select__placeholder{color:#979aae}
.form-group .react-select{text-align:left}.form-group .react-select .react-select__control{background:#fdffff;color:#343a40;border:0;border:1px solid #625b71;border-radius:4px}
.form-group .react-select .react-select__control.react-select__control--is-focused,.form-group .react-select .react-select__control.react-select__control--is-focused:hover{background:#fdffff;border-color:transparent;outline:0;box-shadow:0 0 0 2px #735cb0}
.form-group .react-select .react-select__menu{background:#fdffff;color:#343a40;border-radius:4px;margin-top:0;box-shadow:none;box-shadow:0 0 0 2px #735cb0}
.form-group .react-select .react-select__menu .react-select__option:active,.form-group .react-select .react-select__menu .react-select__option:hover,.form-group .react-select .react-select__menu .react-select__option:not(:first-child).react-select__option--is-focused:not(:first-child),.form-group .react-select .react-select__menu .react-select__option:not(:first-child).react-select__option--is-focused:not(:first-child):active,.react-select .react-select__menu .react-select__option.react-select__option--is-selected,.react-select .react-select__menu .react-select__option.react-select__option--is-selected:active,.react-select .react-select__menu .react-select__option.react-select__option--is-selected:not(:first-child),.react-select .react-select__menu .react-select__option.react-select__option--is-selected:not(:first-child):active,.react-select .react-select__menu .react-select__option.react-select__option--is-selected.react-select__option--is-focused:not(:first-child),.react-select .react-select__menu .react-select__option.react-select__option--is-selected.react-select__option--is-focused:not(:first-child):active{background:#e8defe !important}
.form-group .react-select.primary .react-select__control .react-select__value-container--has-value .react-select__single-value,.form-group .react-select.primary .react-select__control .react-select__value-container--has-value .react-select__multi-value__label{color:#343a40}
.form-group label,.form-group .form-control{font-size:15px}.form-group label{color:#625b71;font-weight:500;text-transform:none}
.form-group.multi .upload-file-container{flex-direction:row;flex-wrap:wrap;max-height:200px;overflow:auto;align-items:baseline;justify-content:flex-start}
.form-group.multi .upload-file-container:hover{opacity:1}.form-group.multi .upload-file-container:hover .icon-upload-file{opacity:.8}
.form-group.multi .icon-upload-file,.form-group.multi .upload-file-container .file-and-text-container{width:100px}
.form-group.multi .upload-file-container .file-container.add-new{flex-direction:column;align-items:center}
.form-group.multi .upload-file-container .file-container.add-new.centered{margin:0 auto}
.form-group .upload-file-container{position:relative;border:1px solid #625b71;border-radius:4px;padding:15px;display:flex;justify-content:center;align-items:center;cursor:pointer;transition:.2s all;overflow:hidden;flex-direction:column}
.form-group .upload-text-and-btn{align-items:center;color:#888ba1;margin-top:10px}
.form-group .upload-file-container .edit-btn{position:absolute;right:5px;top:5px}
.form-group .upload-file-container .edit-btn:hover{opacity:.8}.form-group .upload-text-and-btn button{margin-left:15px;background:#979aae;color:white;border:0;transition:.2s all}
.form-group .bottom-tags-input{background-color:#fff;border-top:2px solid #efefef;overflow:hidden;padding-bottom:5px;transition:all 60ms ease-in}
.form-group .upload-text-and-btn button:hover,.form-group .upload-file-container:hover{opacity:.8}
.form-group .upload-file-container .upload-image-icon{border-radius:6px;overflow:hidden}
.form-group .upload-file-container .upload-image-icon img,.form-group .upload-file-container img{width:200px;border-radius:6px;overflow:hidden}
.form-group .upload-file-container .hidden-input-file{display:none}.form-group .upload-file-container .selected-file-name{font-style:italic;margin-top:10px;overflow-wrap:break-word;text-align:center}
.form-group .upload-file-container .file-and-text-container{margin-top:10px;color:#979aae}
.form-group .upload-file-container .file-and-text-container:last-child{margin-top:0}
.form-group .upload-file-container .file-container{position:relative;display:flex;justify-content:center}
.form-group .upload-file-container .file-container .delete-file-icon{margin-left:10px;margin-top:-10px;opacity:0;transition:.2s all;position:absolute;right:0}
.form-group .upload-file-container .file-container .delete-file-icon path{fill:#e4437d}
.form-group .upload-file-container .file-container .delete-file-icon:hover{opacity:.8}
.form-group .file-and-text-container:hover .delete-file-icon{opacity:1}.form-group .file-and-text-container .text-container{word-break:break-all}
.form-group .react-datetime-picker{display:block;font-size:14px}.react-tagsinput--focused{border:0}
label.info-text{color:#979aae;font-weight:400}div[class*="-menu"]{z-index:2}
.form-group .react-select.primary.text-warning .react-select__control .react-select__value-container--has-value .react-select__single-value{color:#f07c3c}
.react-select .react-select__control .react-select__indicators{width:20px}.icon-delete,.arrow-upward-icon,.arrow-downward-icon{cursor:pointer}
.arrow-upward-icon:hover,.arrow-downward-icon:hover{opacity:.8}.fa.special{color:#f07c3c}
.info-message{margin:10px 0;padding:10px 0;text-align:center;border-radius:4px}.info-message.neutral{background-color:#f7f7f8;color:#bfbfbf}
.info-message.danger{color:#e4437d;color:#fff}.info-message.success{color:#87c860;color:#fff}
.operator{border:1px solid white;padding:15px 0 0 15px;margin-bottom:5px;background:green;border-radius:5px}
.sidebar{z-index:10;background:#f9fafe}.sidebar:before,.sidebar:after{content:none;opacity:1}
.sidebar .sidebar-wrapper{padding-bottom:20px;display:flex;flex-direction:column}
.sidebar .user{border-top:1px solid rgba(255,255,255,0.2);border-bottom:0;padding:20px 0 0 0}
.sidebar .workspace .photo,.sidebar .switch-role .photo{min-width:43px}.sidebar .user .photo{display:flex}
.sidebar .user .photo i:before{font-size:28px;width:30px;color:#625b71}.sidebar .user .nav .nav-item .nav-link p{text-transform:none}
.sidebar .user .info a{color:#625b71;cursor:pointer;line-height:unset;font-size:14px;font-weight:400}
.sidebar .user .info a span{font-size:13px;font-weight:500}.sidebar .user .info .caret{right:0;top:0;margin-top:5px}
.sidebar .logo{padding:0;height:55px;display:flex;align-items:center}.sidebar .logo:before{background-color:#625b71}
.sidebar .logo img{width:130px;max-height:unset;margin-left:15px}.sidebar .tab-content{display:flex;flex-direction:column;flex-grow:1}
.sidebar .workspace,.sidebar .switch-role{display:flex;position:relative;color:#fff;margin:5px 15px 0 11px;text-transform:uppercase;line-height:30px;font-size:12px;padding:10px 15px;white-space:nowrap}
.sidebar .bottom-group{margin-top:auto;padding-top:50px}.sidebar .user{margin-top:0;padding:0}
.sidebar .workspace .info,.sidebar .switch-role .info{width:100%}.sidebar .workspace a,.sidebar .switch-role a{display:block;font-weight:600;color:white;cursor:pointer;width:160px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;color:#625b71}
.sidebar .nav .nav-link a,.sidebar .user .nav-link{transition:all 150ms ease-in}.sidebar .nav .nav-item .nav-link{position:relative;color:#625b71;opacity:1}
.sidebar .nav .nav-item .nav-link i{font-size:unset}.sidebar .nav .nav-item:hover .nav-link.static-nav-link,.sidebar .nav .nav-item.active .nav-link.static-nav-link,.sidebar .nav .nav-link.dynamic-nav-link:hover,.sidebar .nav .nav-item.active .nav-link.dynamic-nav-link{opacity:1;color:#50407b;background:#ccecfb !important;padding-left:40px;position:relative}
.sidebar .nav .nav-item .nav-link:before,.sidebar .nav .nav-item .nav-link:before{content:"";position:absolute;height:100%;width:5px;background:#f9fafe;left:0;top:0;transition:all 150ms ease-in}
.sidebar .nav .nav-item.active .nav-link:before,.sidebar .nav .nav-item:hover .nav-link.static-nav-link:before,.sidebar .nav .nav-link.dynamic-nav-link:hover:before{content:"";position:absolute;height:100%;width:5px;background:#735cb0;left:0;top:0}
.sidebar .nav .nav-item a[aria-expanded="true"] p{color:#50407b}
.sidebar .nav .nav-item a[aria-expanded="true"] i path{fill:#50407b}
.sidebar .sidebar-wrapper .nav .nav-link p{color:#625b71;font-weight:700}.sidebar .workspace .photo i,.sidebar .switch-role .photo i{display:inline;margin-right:15px}
.sidebar .workspace .photo i:before,.sidebar .switch-role .photo i:before{font-size:28px;width:30px;color:#04a2b2}
.sidebar .current-role{width:100%;background:white;color:black;padding:10px 0;text-align:center}
.sidebar .nav-tabs{display:flex;flex-wrap:nowrap}.sidebar .nav-tabs .nav-item{flex-grow:1;text-align:center}
.sidebar .nav-tabs .nav-item .nav-link{white-space:nowrap;padding:5px 0}.sidebar .nav-tabs .nav-item .nav-link,.sidebar .nav-tabs .nav-link{background:unset;margin:0;border-radius:0}
.sidebar .nav .nav-item .nav-link{margin-right:0;text-overflow:ellipsis;overflow:hidden}
.sidebar .nav-tabs .nav-link.active,.sidebar .nav-tabs .nav-link.active:hover{background:white;color:black;opacity:1}
.sidebar .nav-item ul{margin-left:20px}.sidebar .fa-arrow-right{transform:translateX(-5px);transition:.2s all}
.sidebar li:hover .fa-arrow-right{opacity:1;transform:translateX(0)}@media all and (min-width:992px){.sidebar-mini .workspace span{transform:translate3d(-25px,0,0);opacity:0}
.sidebar-mini .sidebar{z-index:12}.sidebar-mini .sidebar:hover .workspace span{transform:translate3d(0,0,0);opacity:1}
.sidebar-mini .sidebar .sidebar-wrapper .nav .nav-link p{transform:translate3d(-25px,0,0);opacity:0}
.sidebar-mini .sidebar:hover .sidebar-wrapper .nav .nav-link p{transform:translate3d(0,0,0);opacity:1}
.sidebar-mini .sidebar .nav-tabs{overflow:hidden}}.main-panel .navbar{border-bottom:1px solid #625b71;border:0;height:55px;padding:0 15px 0 15px}
.main-panel .navbar-wrapper{width:100%}.main-panel .navbar-wrapper .left-col{display:flex}
.main-panel .navbar-wrapper .left-col{font-weight:500;color:#625b71;display:flex;align-items:center}
.main-panel .navbar-wrapper .right-col{margin-left:auto}.main-panel .navbar .navbar-logo{font-weight:700}
.main-panel .navbar .back-icon-container{margin-right:25px;cursor:pointer}.main-panel .navbar .back-icon-container:hover{opacity:.8}
.main-panel .navbar-minimize{margin-right:0;margin-left:15px}@media all and (min-width:992px){.navbar-minimize{opacity:0;pointer-events:none;visibility:hidden}
}.sliding-panel{position:fixed;display:flex;flex-direction:column;top:0;right:0;z-index:9999;width:550px;max-width:80%;height:100%;background:#fcffff;border-top:0;overflow-x:hidden;transform:translateX(100%);transition:.3s ease-in-out;box-shadow:-4px 0 8px rgba(0,0,0,0.1);padding-bottom:70px}
.sliding-panel.closed{box-shadow:none}.sliding-panel.open{transform:translateX(0);transition:.3s ease-in-out}
.sliding-panel .record-filters .btn-col{flex-grow:1;display:flex;padding:0;align-items:center;justify-content:end}
.sliding-panel .btn-col .sliding-panel-btn.close-btn{font-size:30px;color:#625b71;margin-bottom:0;cursor:pointer}
.sliding-panel .sliding-panel-btn:hover{opacity:.8}.sliding-panel .group-header,.crossa-page-template .group-header{border-top:1px solid #e3e3e3;padding-top:15px;word-break:break-all;font-weight:500;margin-bottom:15px;color:#625b71}
.sliding-panel.big{width:calc(100% - 260px);max-width:calc(100% - 260px);box-shadow:none;border:0}
.sliding-panel-header{box-sizing:border-box;min-height:54px;display:flex;align-items:center;padding:0 20px;position:relative;color:#625b71;white-space:nowrap}
.sliding-panel-header:before{content:'';position:absolute;bottom:0;border-bottom:1px solid #625b71;left:0;right:0;margin:0 20px}
.sliding-panel-header .btn-col{text-align:right}.sliding-panel-header .btn-col .btn{cursor:pointer}
.sliding-panel-content{padding:15px;overflow:auto;display:flex;flex-direction:column;flex-grow:1}
.sliding-panel-content .record-field-form,.sliding-panel-content .menu-name-form,.sliding-panel-content .role-editor-form,.sliding-panel-content .save-record-form{display:flex;flex-direction:column;flex-grow:1}
.sliding-panel-content .record-field-form .crossa-page-template,.sliding-panel-content .menu-name-form .crossa-page-template,.sliding-panel-content .role-editor-form .crossa-page-template,.sliding-panel-content .save-record-form .crossa-page-template{display:flex;flex-direction:column;flex-grow:1}
.sliding-panel .react-datetime-picker .react-datetime-picker__inputGroup span{height:calc(100% - 2px);display:inline-block}
.sliding-panel.big .container-fluid{width:90%;max-width:1500px;margin:0 auto}.sliding-panel.big .crossa-template-body{display:flex;flex-wrap:wrap}
.sliding-panel.big .crossa-template-body .centered-sliding-content{width:100%;display:flex;flex-wrap:wrap}
.sliding-panel.big .crossa-template-body .centered-sliding-content>:first-child.form-group.group-header{border-top:0}
.sliding-panel.big .crossa-template-body .centered-sliding-content .form-group{margin:0 0 1.5rem 0}
.sliding-panel.big .crossa-template-body .centered-sliding-content .form-group.group-header{width:100%;max-width:100%;border-top:1px solid #e3e3e3;padding-top:20px}
.sliding-panel.big .crossa-template-body .form-group.textarea-form-group{width:100%;max-width:100%;flex-grow:1}
.sliding-panel.big .crossa-template-body .form-group.textarea-form-group p{width:80%;overflow-wrap:break-word}
.sliding-panel.big .crossa-template-body .form-group.file-form-group .grouped-files-container{display:flex;flex-wrap:wrap;margin-top:10px}
.sliding-panel.big .crossa-template-body .form-group.file-form-group .grouped-files-container .file-container{max-width:100px;margin-right:15px;text-align:center}
.sliding-panel.big .crossa-template-body .form-group.file-form-group .grouped-files-container .file-container:last-child{margin-right:0}
.sliding-panel.big .crossa-template-body .form-group.file-form-group .grouped-files-container .file-container:hover{opacity:.8}
.sliding-panel.big .crossa-template-body .form-group.file-form-group .grouped-files-container svg path{fill:#0093d7}
.sliding-panel.big .crossa-template-body .form-group.file-form-group .grouped-files-container .text-container{word-break:break-word;margin-top:10px;color:#979aae}
.sliding-panel.big .crossa-template-body .centered-sliding-content .form-label{color:#625b71}
.sliding-panel.big .crossa-template-body .centered-sliding-content p,.sliding-panel.big .crossa-template-body .centered-sliding-content pre{color:#343a40;font-size:14px}
.sliding-panel.big h4{flex:0 calc(100% - 50px);font-weight:500;margin-bottom:15px;color:#625b71;width:calc(100% - 50px)}
.sliding-panel.big .crossa-template-body .text-area-form-group,.sliding-panel.big .crossa-template-body .text-area-form-group.with-autosize textarea{max-width:calc(100% - 25px);width:calc(100% - 25px)}
.sliding-panel.big .crossa-template-body .text-area-form-group p{width:80%;overflow-wrap:break-word;white-space:pre-wrap;word-wrap:break-word}
.sliding-panel.big .form-group{max-width:50%;width:50%}.sliding-panel.big .form-group .react-datetime-picker{display:block;font-size:.875rem}
.sliding-panel.big .form-group .react-datetime-picker .react-datetime-picker__wrapper{padding-left:10px}
.sliding-panel.big .form-group input,.sliding-panel.big .form-group .row,.sliding-panel.big .form-group .react-select,.sliding-panel.big .form-group .react-tagsinput,.sliding-panel.big .form-group .upload-file-container,.sliding-panel.big .form-group .react-datetime-picker{max-width:calc(100% - 50px);min-width:13px}
.sliding-panel.big .form-group .react-datetime-picker{min-width:.54em}.sliding-panel.big .form-group .row .inline-switch-col{padding-right:0}
.sliding-panel.big .form-group .react-select .react-select__value-container input{width:100% !important}
.sliding-panel.big .form-group .react-tagsinput--focused{border:0}.sliding-panel.big .text-area-form-group textarea{flex:0 100%;max-width:calc(100% - 50px)}
.sliding-panel.big .form-group .form-check{padding:0}.sliding-panel.big .form-group .form-check .form-check-label{padding-left:25px}
.sliding-panel.big .form-group.image-form-group img{border-radius:8px;width:200px}
.sliding-panel .back-icon-sliding-panel{margin-right:25px;cursor:pointer}@media screen and (max-width:991px){.sliding-panel.big{width:100%;max-width:100%}
.sliding-panel.big .form-group{max-width:100%;width:100%}}.table-container{border-radius:4px;border:1px solid rgba(0,0,0,0.125)}
.table-container.no-border{border:0;border-radius:0}.table-hover tbody tr{cursor:pointer}
.table-responsive{overflow:auto;max-height:calc(100vh - 120px)}thead th{white-space:nowrap}
thead th:last-child,.header .th:last-child{text-align:right}table tbody tr td:last-child,table .body .tr .td:last-child{text-align:right}
.table .actions-right{text-align:right;display:flex;justify-content:end;padding-right:15px}
.table.draggable tr.selected,.table.draggable tr:hover,.table.draggable tr.dragging{background-color:#ccecfb;color:#50407b}
.table.draggable tr.dragging{box-shadow:0 0 16px 2px rgba(0,0,0,0.15)}.table-with-group-headers tbody tr,.table-with-group-headers .body .tr{border-left:1px solid #dee2e6;border-right:1px solid #dee2e6;border-bottom:1px solid #dee2e6}
.table-with-group-headers tbody tr.no-borders,.table-with-group-headers .body .tr.no-borders{border-left:0;border-top:0;border-right:0}
.table-with-group-headers tbody tr.empty,.table-with-group-headers thead tr.empty{transition:none}
.table-with-group-headers tbody tr.empty:hover,.table-with-group-headers thead tr.empty:hover{background:initial;box-shadow:none}
.table-with-group-headers thead tr.no-borders td,.table-with-group-headers .header .tr.no-borders .td{border:0}
.table-with-group-headers thead td,.table-with-group-headers .header .td{color:#888ba1;font-weight:600}
.table-fixed-width th,.table-fixed-width .th,.table-fixed-width td,.table-fixed-width .td{width:50%}
.table .single-icon-td{width:40px}.table td,.table .td{max-width:150px}.table-bigboy>tbody>tr>td,.table-bigboy>.body>.tr>.td{vertical-align:top}
.table td.textarea,.table .td.textarea{max-width:300px}.table .status{color:white;justify-content:center;border-radius:8px;font-size:12px;padding:2px 9px;display:inline-block}
.table .description-td{width:30%}.table p{-webkit-line-clamp:5;display:-webkit-box;-webkit-box-orient:vertical;overflow:hidden}
.table-bigboy .img-container{display:flex;align-items:center;width:130px;height:unset}
.display-fields-container table td{word-break:break-all}.table.sticky.table-responsive{max-height:calc(100vh - 120px);height:calc(100vh - 140px)}
.table.sticky{display:table}.table.sticky .body{display:table-row-group;position:relative;z-index:0}
.table.sticky .header{display:table-header-group;position:sticky;z-index:1;top:0;width:fit-content;background:#fff;color:#9a9a9a;font-size:.875rem;font-weight:400;text-transform:uppercase;border-bottom:1px solid #e3e3e3;white-space:nowrap}
.table.sticky .header:before{content:"";position:absolute;z-index:999;bottom:0;left:0;height:1px;background:#e3e3e3;width:100%}
.table.sticky .th[data-sticky-td],.table.sticky .td[data-sticky-td]{background:#fff;position:sticky}
.table.sticky .tr{border-bottom:1px solid #e3e3e3;display:table-row}.table.draggable tr,.table.draggable .tr{transition:.2s all}
.table.sticky .th,.table.sticky .td{padding:12px 8px;display:table-cell}.table.sticky .th{padding:6px 8px}
.table .tr.active{background:#ccecfb}.table .tr.active .td{border-color:#fff}.table.sticky .td{border-bottom:1px solid #dee2e6}
.table.sticky .tr:last-child .td{border-bottom:0}.table.sticky .td p,.table.sticky .th div{font-size:14px}
.table.sticky .th.checkbox-row,.table.sticky .td.checkbox-row{width:20px}.table.sticky .th.checkbox-row .fake-input{margin-left:5px}
.table.sticky .th.checkbox-row .form-check-sign,.table.sticky .th.checkbox-row .form-check-sign:before,.table.sticky .th.checkbox-row .form-check-sign:after,.table.sticky .td.checkbox-row .form-check-sign,.table.sticky .td.checkbox-row .form-check-sign:before,.table.sticky .td.checkbox-row .form-check-sign:after{margin-top:0}
.table.sticky .th.checkbox-row .form-check,.table.sticky .td.checkbox-row .form-check{padding-left:0}
.table.sticky .form-group{margin-bottom:0}.table-widget{border:1px solid #c7cadc;border-radius:4px;padding:20px}
.crossa-tabs-container .crossa-tabs{border:0}.crossa-tabs-container .crossa-tabs.nav-tabs .nav-link{border:0;position:relative;padding:0;margin-right:30px;color:#625b71;font-weight:500}
.crossa-tabs-container .crossa-tabs.nav-tabs .nav-link:last-child{margin-right:0}
.crossa-tabs-container .crossa-tabs.nav-tabs .nav-link.active,.crossa-tabs-container .crossa-tabs.nav-tabs .nav-link.active.has-errors{color:#50407b;border:0;font-weight:700}
.crossa-tabs-container .crossa-tabs.nav-tabs .nav-link.active:after,.crossa-tabs-container .crossa-tabs.nav-tabs .nav-link.active.has-errors:after{content:'';position:absolute;left:0;right:0;margin:auto;bottom:-5px;height:4px;background:#735cb0}
.crossa-tabs-container .crossa-tabs.nav-tabs .nav-link.has-errors{color:#e4437d}.crossa-tabs-container .tab-content{margin-top:15px}
.crossa-tabs-container .crossa-tabs.crossa-scrollable-tabs{padding-bottom:10px}.crossa-tabs-container .crossa-tabs.crossa-scrollable-tabs{overflow-y:auto;flex-wrap:nowrap;display:flex;white-space:nowrap}
.top-row{align-items:center}.top-row .title h3{margin:0}.top-row .actions{text-align:right}
.top-row .actions button{margin-right:5px}.top-row .actions button:last-child{margin-right:0}
.tree-menu{padding:10px}.tree-menu .row{width:100%;margin:0;cursor:pointer;flex-wrap:nowrap}
.tree-menu .row .menuDiv{display:flex;word-break:break-all}.tree-menu p{font-weight:700}
.tree-menu .row .actions ~ .menuDiv{padding-left:0}.tree-menu .row .menuDiv img{margin-right:5px;margin-left:5px;width:20px}
.tree-menu .tree-item-children .selected,.tree-item-children .tree-item:hover{background-color:#ccecfb;color:#50407b}
.tree-menu .tree-item-children .selected p{font-weight:700}.tree-menu .tree-item-children .tree-item .row:before{content:"";position:absolute;height:100%;width:5px;background:#fff;left:0;top:0;border-radius:3px;overflow:hidden;transition:all 150ms ease-in}
.tree-menu .tree-item-children .tree-item .row.selected:before,.tree-menu .tree-item-children .tree-item .row:hover:before{content:"";position:absolute;height:100%;width:5px;background:#735cb0;left:0;top:0}
.tree-menu .selected p,.tree-item:hover p{color:#50407b}.tree-menu .selected .view-icon path,.tree-item:hover .view-icon path{fill:#50407b}
.tree-menu button{align-self:center;padding:0;margin:0;border:0;font-size:13px;min-width:19px}
.tree-menu .tree-item .row{padding:10px 0}.tree-menu .tree-item-children{padding-left:20px}
.tree-menu .tree-item-children p{font-weight:500;padding-left:5px;transition:all 150ms ease-in}
.tree-menu .tree-item-children .btn-link,.view-rules-container .btn-link{color:#0093d7;font-weight:700;font-size:14px}
.tree-menu .tree-item-children .btn-link:hover{opacity:.8}.tree-menu .tree-item-children .btn-link.btn-danger{margin-right:5px}
.tree-menu .tree-item-children .icon{margin-right:5px;margin-bottom:2px}.tree-menu .tree-item-children .selected p,.tree-menu .tree-item-children .tree-item:hover p{padding-left:30px}
.tree-menu .tree-item-children .tree-item{margin:2px 0;position:relative;transition:all 150ms ease-in}
.tree-menu .view-icon-container{margin-right:10px}.tree-menu .actions{margin-left:auto;overflow:hidden}
.tree-menu .actions button{transform:translateX(150%);transition:.2s all}.tree-menu .tree-item:hover .actions button{transform:translateX(0)}
.simple-menu .label{color:#625b71;font-weight:500;margin-bottom:15px;font-size:20px}
.simple-menu .row{width:100%;margin:0;cursor:pointer;flex-wrap:nowrap}.simple-menu .row .menuDiv{display:flex;word-break:break-all;width:100%}
.simple-menu .row .actions ~ .menuDiv{padding-left:0}.simple-menu .row .menuDiv img{margin-right:5px;margin-left:5px;width:20px}
.simple-menu .tree-item .selected,.simple-menu .tree-item:hover,.table.draggable tr.selected,.table.draggable tr:hover{background-color:#ccecfb;color:#50407b}
.simple-menu .tree-item.dragging,.table.draggable tr.dragging{box-shadow:0 0 16px 2px rgba(0,0,0,0.15)}
.simple-menu .tree-item .selected p{font-weight:700}.simple-menu .tree-item .has-errors p{color:#e4437d}
.simple-menu .tree-item .row:before{content:"";position:absolute;height:100%;width:5px;background:0;left:0;top:0;border-radius:3px;overflow:hidden;transition:all 150ms ease-in}
.simple-menu .tree-item .row.selected:before,.simple-menu .tree-item .row:hover:before{content:"";position:absolute;height:100%;width:5px;background:#735cb0;left:0;top:0}
.simple-menu .selected p,.tree-item:hover p{color:#50407b}.simple-menu .selected svg path,.tree-item:hover svg path{fill:#50407b}
.simple-menu button{align-self:center;padding:0;margin:0;border:0;font-size:13px;min-width:19px}
.simple-menu .tree-item .row{padding:10px 0}.simple-menu p{font-weight:500;max-width:100%;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;transition:all 150ms ease-in}
.simple-menu .add-btn{color:#0093d7;font-weight:700;font-size:14px;margin-top:15px}
.simple-menu .add-btn:hover{opacity:.8;color:#0093d7}.simple-menu .btn-link.btn-danger{margin-right:5px}
.simple-menu .icon{margin-right:5px;margin-bottom:2px}.simple-menu .selected p,.simple-menu .tree-item:hover p,.simple-menu .tree-item.dragging p{padding-left:30px}
.simple-menu .tree-item{margin:2px 0;position:relative;background:#fdffff;border-radius:3px;overflow:hidden}
.simple-menu .view-icon-container{margin-right:10px}.simple-menu .actions{margin-left:auto;overflow:hidden;min-width:24px}
.simple-menu .actions button{transform:translateX(150%);transition:.2s all}.simple-menu .tree-item:hover .actions button{transform:translateX(0)}
.simple-menu.settings-menu .selected p,.simple-menu.settings-menu .tree-item:hover p{padding-left:0}
.simple-menu.settings-menu .selected span,.simple-menu.settings-menu .tree-item:hover span{padding-left:30px}
.rule-component{border-style:solid;border-color:white;padding:20px 0 5px 10px;margin-bottom:5px;background-color:#f7f7f8;border-radius:5px}
.rule-card{background:#f9fafe;border:1px solid #e3e3e3;padding:20px}.rule-card .card-body{padding:0;position:relative}
.action-component,.condition-component{margin-bottom:5px;margin-top:5px}.rule-editor{margin:10px 0}
.rule-editor:first-child{margin-top:0}.rule-editor:last-child{margin-bottom:0}.condition-editor,.action-editor{margin:10px 0}
.condition-editor:last-of-type,.action-editor:last-of-type{margin-bottom:0}.rule-editor .row,.condition-editor .row,.action-editor .row{align-items:baseline;border:1px solid #dee2e6;padding:10px 0;margin:0;position:relative}
.rule-editor-actions{position:absolute;width:35px;height:35px;background:white;border:1px solid #dee2e6;display:flex;align-items:center;justify-content:center;border-radius:50px;top:-25px;right:-15px;transition:.2s all}
.rule-editor-actions:hover{background:#e4437d}.rule-editor-actions:hover svg path{fill:white}
.rule-editor-actions .icon-delete{display:flex;align-items:center}.rule-editor .form-group,.condition-editor .form-group,.action-editor .form-group{max-width:100%;margin:0}
.condition-editor .form-control,.action-editor .form-control{min-height:unset;height:unset}
.condition-editor .col,.action-editor .col,.rule-editor .col{flex:0 0 180px;margin:10px 0}
.condition-editor .col.bigger,.action-editor .col.bigger,.rule-editor .col.bigger{flex:0 0 230px}
.condition-editor .col.buttons-col,.action-editor .col.buttons-col,.rule-editor .col.buttons-col{flex:0}
.rule-editor .buttons-col{flex:0}.rule-record-type-page .btn-link{display:flex;align-items:center}
.rule-record-type-page .btn-link,.rule-record-type-page .btn.btn-link:hover,.rule-record-type-page .btn.btn-link:focus,.rule-record-type-page .btn.btn-link:active{color:#0093d7;font-weight:700;font-size:14px;padding-left:0;padding-right:0;margin-top:20px}
.rule-record-type-page .btn-link:hover,.rule-record-type-page .btn.btn-link:hover:hover,.rule-record-type-page .btn.btn-link:focus:hover,.rule-record-type-page .btn.btn-link:active:hover{opacity:.8}
.condition-editor .condition-select.has-errors .react-select__control.react-select__control{border-color:#e4437d}
.condition-editor .condition-select.has-errors .react-select__control.react-select__control--is-focused,.condition-editor .condition-select.has-errors .react-select__control.react-select__control--is-focused:hover{border-color:#0093d7}
.condition-editor .btn-group,.action-editor .btn-group{margin:15px}.form-builder,.form-builder-inner{position:fixed;top:0;left:0;right:0;bottom:0;width:100%;z-index:1030;background:#fdffff}
.form-builder .top-row{position:fixed;top:0;left:0;right:0;z-index:1031;height:50px;min-height:unset;display:flex;align-items:center;justify-content:center;padding:0;border-bottom:2px solid #625b71;width:100%;margin:auto}
.form-builder .top-row a{margin:0 5px;font-weight:500;cursor:pointer}.form-builder .top-row a:hover{opacity:.7}
.form-builder .top-row .exit-col{padding-right:0;text-align:left}.form-builder .top-row .exit-col a{padding-left:15%}
.form-builder .top-row .tab-navigation-col{text-align:center}.form-builder .top-row .actions-col{padding-left:0;text-align:right;display:flex}
.form-builder .top-row .actions-col a.preview,.form-builder .top-row .actions-col a.hide-preview{text-align:left;display:flex;align-items:center;margin-left:auto;margin-right:50px}
.form-builder .top-row .actions-col a.preview svg{margin-right:5px;min-width:22px}
.form-builder .top-row .actions-col a.save{padding-right:15%}.form-builder .top-row ul{margin-bottom:0;padding:0}
.form-builder .top-row .tab{color:#625b71;position:relative;margin-right:50px}.form-builder .top-row .tab:last-child{margin-right:0}
.form-builder .top-row .tab.active{color:#735cb0}.form-builder .top-row .tab.active:after{content:'';position:absolute;bottom:-6px;left:0;right:0;width:100%;height:4px;background-color:#735cb0}
.form-builder .btn-link-primary{color:#0093d7}.form-builder .btn-link-success{color:#87c860}
.form-builder .form-builder-body{margin-top:50px;height:calc(100% - 50px);display:flex}
.form-builder .form-builder-body .left-col{width:450px;max-width:450px;height:100%;overflow:auto}
.form-builder .form-builder-body .title{border-bottom:2px solid #625b71;padding:15px 0;margin-bottom:30px}
.form-builder .form-builder-body .title p{font-weight:500}.form-builder .form-builder-body .left-col .title{max-width:450px}
.form-builder .form-group.full-width{max-width:100%}.form-builder .draggable-form-groups .form-group,.form-builder .fields-wrapper .form-group{border-radius:4px;box-shadow:0 0 4px 0 rgba(0,0,0,0.2);padding:15px;box-sizing:border-box;transition:.2s all;background:#FFF}
.form-builder .form-builder-phone-preview .form-group{background:0}.form-builder .draggable-form-groups .selected .form-group,.form-builder .fields-wrapper .selected .form-group{background:#ccecfb}
.form-builder .draggable-form-groups .form-group .form-check{padding-left:1.25rem}
.form-builder .form-group .form-description{color:#888ba1;font-size:13px;margin-bottom:10px}
.form-builder .draggable-form-groups .form-group .form-control,.form-builder .draggable-form-groups .form-group .react-select{pointer-events:none}
.form-builder .form-builder-body .center-col{box-shadow:0 0 24px rgba(0,0,0,0.15);flex:0 calc(100% - 450px - 450px - 70px);justify-content:center;margin:0 auto;background:#fff}
.form-builder .form-check input[type="checkbox"],.form-builder .form-check-radio input[type="radio"]{opacity:1;visibility:visible}
.form-builder .not-editable.form-group{margin-bottom:0}.form-builder .not-editable.form-group input{border:0;background:0;padding:0;height:unset;font-weight:500;font-size:16px}
.form-builder .not-editable.form-group label{display:none}.form-builder .fields-wrapper{overflow:auto;height:calc(100% - 110px);padding:5px;overflow-x:hidden}
.form-builder .form-settings-fields-wrapper{overflow:auto;height:calc(100% - 110px)}
.form-builder .fields-wrapper.full-width .form-group{max-width:100%}.form-builder .fields-wrapper.full-width .form-group input,.form-builder .fields-wrapper.full-width .form-group .react-select,.form-builder .fields-wrapper.full-width .form-group .react-datetime-picker{max-width:50%}
.form-builder .form-builder-phone-preview .fields-wrapper.full-width .form-group input,.form-builder .form-builder-phone-preview .fields-wrapper.full-width .form-group .react-select,.form-builder .form-builder-phone-preview .fields-wrapper.full-width .form-group .react-datetime-picker{max-width:100%}
.form-builder .form-builder-desktop-preview .fields-wrapper.full-width .form-group input,.form-builder .form-builder-desktop-preview .fields-wrapper.full-width .form-group .react-select,.form-builder .form-builder-desktop-preview .fields-wrapper.full-width .form-group .react-datetime-picker{max-width:450px}
.form-builder .fields-wrapper.full-width .form-group button{transform:translateX(100%)}
.form-builder .fields-wrapper.full-width .form-group .react-datetime-picker button{transform:none}
.form-builder .fields-wrapper.full-width .form-group:hover button{transform:translateX(0)}
.form-builder .clickable-form-group-container input{cursor:pointer}.form-builder .right-col,.form-builder .sliding-panel{width:450px;max-width:450px;margin-top:50px;padding-bottom:150px;height:calc(100% - 50px)}
.form-builder .sliding-panel{overflow:hidden}.form-builder .sliding-panel-inner{padding:0 20px;overflow:auto;height:100%}
.form-builder .sliding-panel .title{padding-left:20px}.form-builder .sliding-panel-header:before{border:1px solid #625b71}
.form-builder .buttons-row{margin:0;position:fixed;bottom:10px;right:30px;display:flex;justify-content:right;height:45px;z-index:999}
.form-builder .buttons-row button:first-child{margin-right:15px}.form-builder .switch-label{margin-right:15px !important;min-width:calc(100% - 60px)}
.form-builder .switch-label,.form-builder .form-label{font-size:14px;font-weight:500;color:#625b71}
.form-builder .form-label.red{color:red}.form-builder .form-group textarea.textarea-input{width:100%;min-height:150px}
.form-builder .inline-inputs{max-width:450px}.form-builder .date-picker-row.inline-inputs .form-group:last-child{margin-left:auto}
.form-builder .theme-card{border:1px solid #cfcfcf;border-radius:4px;overflow:hidden;margin-right:25px;position:relative;transition:.2s all;cursor:pointer;pointer-events:all}
.form-builder .theme-card:hover{transform:scale(1.03)}.theme-card.disabled{cursor:not-allowed}
.theme-card.disabled:after{position:absolute;top:0;left:0;right:0;bottom:0;content:'';background:rgba(227,227,227,0.6)}
.form-builder .theme-card.theme1{background:#fff}.form-builder .theme-card.theme2{background:#c2b594}
.form-builder .theme-card.theme3{background:#e3c6fa}.form-builder .theme-card .theme-card-img{padding:0 35px}
.form-builder .theme-card .theme-card-label{background:#f3f3f3;color:#50407b;font-weight:500;padding:10px;box-shadow:0 -2px 4px rgba(0,0,0,0.1)}
.form-builder .theme-card.active .theme-card-label,.form-builder .theme-card.active .theme-card-img{background:#ccecfb}
.form-builder .theme-card.disabled .theme-card-label{background:#f3f3f3;color:#625b71}
.form-builder .fa-trash{cursor:pointer}.form-builder .form-builder-phone-preview .form-builder-body .center-col{background-size:100% 100%;background-repeat:no-repeat;background-position:center;height:78vh;flex-basis:37vh;margin-top:6vh;overflow:hidden;position:relative;display:block;box-shadow:none;border-radius:60px}
.form-builder .form-builder-phone-preview .fields-wrapper{height:calc(100% - 200px);-ms-overflow-style:none;scrollbar-width:none}
.form-builder .form-builder-phone-preview .fields-wrapper::-webkit-scrollbar{display:none}
.form-builder .form-builder-phone-preview .fields-wrapper::-webkit-scrollbar-track{display:none}
.form-builder .form-builder-phone-preview .fields-wrapper::-webkit-scrollbar-thumb{display:none}
.form-builder .form-builder-phone-preview .fields-wrapper::-webkit-scrollbar-button{display:none}
.form-builder .form-builder-phone-preview .fields-wrapper.full-width .form-group,.form-builder .form-builder-desktop-preview .fields-wrapper.full-width .form-group{max-width:100%;box-shadow:none;margin:0}
.form-builder .form-builder-phone-preview .fields-wrapper .form-group input{max-width:100%}
.form-builder .form-builder-phone-preview input,.form-builder .form-builder-phone-preview label,.form-builder .form-builder-phone-preview .react-select{max-width:100%;cursor:not-allowed;pointer-events:none}
.form-builder .form-builder-desktop-preview input,.form-builder .form-builder-desktop-preview label,.form-builder .form-builder-desktop-preview .react-select{cursor:not-allowed;pointer-events:none}
.form-builder .form-builder-phone-preview .title{padding-top:100px;width:90%;margin:0 auto}
.form-builder .droppable-container{min-height:100%;display:flex;flex-direction:column}
.form-builder .droppable-container .payment-form-group-container{order:99999;cursor:pointer}
@media screen and (max-width:1600px){.form-builder .form-builder-body .left-col{width:450px;max-width:450px}
.form-builder .right-col,.form-builder .sliding-panel{width:450px;max-width:450px}
.form-builder .form-builder-body .center-col{flex-grow:1;flex:1 calc(100% - 450px - 450px - 35px)}
.form-builder .form-builder-phone-preview .center-col{flex-grow:0}}.login-container{background:#f9fafe}
.login-container:before,.login-container:after{content:none}.login-container .content{min-height:calc(100vh - 60px);z-index:4;position:relative}
.login-card-container.hidden{opacity:0;transform:translateY(-60px)}.login-card-container{transform:translate3d(0,0,0);transition:all 300ms linear;opacity:1}
.login-container .logo-container{display:flex;justify-content:center;margin-bottom:15px}
.colour-picker-form-group{position:relative}.colour-picker-form-group .colour{margin:5px;border-radius:2px;width:24px;min-width:24px;height:24px;cursor:pointer;transition:.2s all}
.colour-picker-form-group .colour.selected-colour{margin-right:0;margin-left:20px}
.colour-picker-form-group .colour.cancel-colour{border:1px solid #625b71;padding:2px}
.colour-picker-form-group .colour:hover{opacity:.8}.colour-popup{display:flex;padding:10px;max-width:258px;background:white;box-shadow:0 2px 8px rgba(0,0,0,0.1);border-radius:4px;flex-wrap:wrap;position:absolute;top:-35px;right:50px}
.colour-picker{margin-left:20px;cursor:pointer}.colour-picker:hover{opacity:.8}.colour-option-row{flex-wrap:nowrap}
.colour-option-row .delete-btn-container{margin-left:20px;cursor:pointer}.custom-switch{min-width:unset;min-height:unset}
.custom-switch .custom-control-label{cursor:pointer}.custom-switch .custom-control-label::before,.custom-control-input:checked ~ .custom-control-label::before,.custom-control-input:not(:disabled):active ~ .custom-control-label::before{border:2px solid #343a40;background:#fff;width:39px !important;height:23px !important}
.custom-control-input:checked ~ .custom-control-label::before,.custom-control-input:checked:not(:disabled):active ~ .custom-control-label::before{border-color:#87c860;background:0}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after{background:#87c860}
.custom-control-input:focus ~ .custom-control-label::before{box-shadow:none}.custom-switch .custom-control-label::after{width:13px !important;height:13px !important;top:5px;left:5px;background-color:#343a40}
.custom-switch:hover .custom-control-label::after{width:13px !important}.custom-switch:hover .custom-control-input:checked ~ .custom-control-label::after{margin-left:0}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after{transform:translateX(15px)}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before{border-color:#343a40}
.inline-switch-col{text-align:right;padding-right:30px}.custom-switch .custom-control-label{min-width:20px;cursor:pointer}
.crossa-page-template{padding-top:70px}.sliding-panel .crossa-page-template{padding-top:0}
.crossa-template-body{padding-bottom:50px}.crossa-template-body .form-group .form-check{padding-left:0}
.alert{box-shadow:0 4px 12px rgba(0,0,0,0.15);border-radius:5px;max-width:350px;margin:0 auto}
.alert-success{background:#87c860}.alert-danger{background:#e4437d}.alert span.fa{font-size:20px;left:15px;position:absolute;top:0;bottom:0;margin:auto;display:flex !important;align-items:center}
.alert button.close{opacity:.8;line-height:9px;width:20px;height:20px;font-size:18px;margin-top:0;transform:translateY(-50%);color:#625b71}
.alert .close ~ span{font-weight:700;max-width:90%}e .rna-container .alert button.close span{margin-top:0}
.alert.alert-with-icon{margin-bottom:5px}.error-message{width:100%;margin-top:.25rem;font-size:80%;color:#e4437d}
.filters-accordion .card .card-header{cursor:pointer;border:0 !important}.filters-accordion .card .card-header .card-title a{display:flex;margin:0}
.filters-accordion .card .card-header .left-col{font-weight:500}.filters-accordion .card .card-header .right-col{margin-left:auto}
.filters-accordion .card .card-header .right-col .caret{margin-left:10px;border-width:5px}
.filters-accordion .card .card-body .row{transition:.2s all}.filters-accordion .card .card-body .row{align-items:center;cursor:pointer;padding:10px 0}
.filters-accordion .card .card-body .row:hover{background:#ccecfb}.filters-accordion .card.date-card .card-body .row{padding:0;cursor:pointer;padding:0}
.filters-accordion .card.date-card .card-body .row:hover{background:unset}.filters-accordion .card .card-body .right-col .form-group{text-align:right;margin-bottom:0}
.chips-container{margin:10px 0;display:flex;flex-wrap:wrap;white-space:nowrap;position:relative;padding-right:50px;height:calc(38px + 4px + 10px);overflow:hidden}
.chips-container.expanded{height:auto}.chips-container .top-right-col{display:flex;justify-content:center;width:80px;position:absolute;right:0;top:12px}
.chips-container .top-right-col .row{align-items:center;margin-right:0}.chips-container .top-right-col .action{cursor:pointer;margin-right:20px}
.chips-container .top-right-col .action:hover{opacity:.8}.chips-container .top-right-col .action:last-child{margin-right:0}
.chip{padding:5px 10px;border:2px solid #7b61ff;color:#001e31;border-radius:8px;position:relative;font-weight:500;margin:7px;overflow:hidden;height:38px;font-size:16px}
.chip.active{border:2px solid #e9ddff;background:#e9ddff;color:#22005d}.chip.disabled{border:2px solid #c7cadc;color:#c7cadc}
.chip .delete-icon{position:absolute;right:5px;top:0;bottom:0;margin:auto;cursor:pointer;display:flex;align-items:center;transform:translateX(150%);transition:.2s all}
.chip .delete-icon:hover svg path{fill:#e4437d}.chip:hover .delete-icon{transform:translateX(0);transition:.2s all;background:#e9ddff;padding:5px}
.chips-container.selectable-chips:hover,.chips-container.selectable-chips.selected{background:#ccecfb;cursor:pointer;border-radius:4px}
.search-and-filter-container,.search-container,.filter-container{display:flex}.search-and-filter-container .search-form-group{margin-bottom:0}
.search-and-filter-container .form-group.search-form-group{position:relative}.search-and-filter-container .form-group.search-form-group .search-icon-container{position:absolute;left:10px;top:0;bottom:0;margin:auto;display:flex;align-items:center}
.search-and-filter-container .form-group.search-form-group .search-icon-container svg{height:15px;width:15px}
.search-and-filter-container .form-group.search-form-group input{padding-left:30px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}
.search-and-filter-container .filter-icon{margin-left:10px;display:flex;align-items:center;cursor:pointer}
.search-and-filter-container .filter-icon:hover{opacity:.8}.search-and-filter-container .btn-link{display:flex;align-items:center}
.search-and-filter-container .btn-link,.search-and-filter-container .btn.btn-link:hover,.search-and-filter-container .btn.btn-link:focus,.search-and-filter-container .btn.btn-link:active{color:#0093d7;font-weight:700;font-size:14px;padding-left:0;padding-right:0}
.search-and-filter-container .btn-link:hover,.search-and-filter-container .btn.btn-link:hover:hover,.search-and-filter-container .btn.btn-link:focus:hover,.search-and-filter-container .btn.btn-link:active:hover{opacity:.8}
.notifications .notification-col{max-width:25%;flex-basis:unset}.notifications .notification-col .title-and-checkbox-container{flex-wrap:nowrap}
.notifications .notification-col .title-and-checkbox-container .title{max-width:80%}
.notifications .notification-col .fake-input,.fake-input{position:relative;height:20px;width:20px;line-height:20px;margin-right:10px;cursor:pointer}
.notifications .notification-col .fake-input input,.fake-input input{opacity:0;position:absolute;top:0;left:0;right:0;bottom:0;margin:auto;cursor:pointer}
.notifications .notification-col .fake-input i,.fake-input input i{position:absolute;left:0;pointer-events:none}
.notifications .badge-container{max-width:80%;margin-top:10px}@media screen and (max-width:1600px){.notifications .notification-col{max-width:33.3%}
}@media screen and (max-width:1100px){.notifications .notification-col{max-width:50%}
}@media screen and (max-width:600px){.notifications .notification-col{max-width:100%}
}.page-404{background:linear-gradient(270deg,#ccecfb 0,#e8defe 100%);height:100vh;overflow:hidden;display:flex;flex-direction:column;justify-content:center;align-items:center;position:relative}
.page-404 .logo-container{margin-bottom:100px}.page-404 .logo-img{height:120px}.page-404 .content-row{width:100%}
.page-404 .left-col{position:absolute;left:5%;bottom:0;height:50vh}.page-404 .left-col img{height:100%}
.page-404 .right-col{display:flex}.page-404 .right-col .title-col{text-align:right;margin-right:100px}
.page-404 .right-col .title-col:after{content:'';position:absolute;right:-50px;height:100%;width:3px;background:#343a40;top:0}
.page-404 .right-col .title-col h1{font-size:10vw;font-weight:500;line-height:.8;color:#343a40}
.page-404 .right-col .text-col{display:flex;flex-direction:column;align-items:baseline;justify-content:space-around}
.page-404 .right-col .text-col p.bigger{font-size:3rem;line-height:normal;font-weight:600;color:#343a40}
.page-404 .right-col .text-col p.smaller{font-size:35px;font-weight:500;color:#625b71}
.page-404 .btn{font-weight:500;box-shadow:0 2px 4px rgba(0,0,0,0.2)}@media screen and (max-width:1326px){.page-404 .right-col{display:flex;flex-direction:column;justify-content:center;max-width:50%;margin-left:auto}
.page-404 .right-col .title-col{text-align:left}}.payment-status-success{color:white;justify-content:center;border-radius:8px;font-size:12px;padding:2px 9px;display:inline-block;background:#87c860}
.payment-status-danger{color:white;justify-content:center;border-radius:8px;font-size:12px;padding:2px 9px;display:inline-block;background:#e4437d}
.chart-widget{min-width:calc(33.33% - 20px);border:1px solid #c7cadc;border-radius:4px;min-height:300px;display:inline-flex;margin-right:25px}
.container-widgets{display:flex;flex-flow:wrap}.widget{margin-right:20px;margin-bottom:20px}
.widget .title{font-weight:600;color:#625b71;margin-bottom:10px}.widget .description{font-size:13px;color:#888ba1;margin-bottom:10px}
.welcome-widget{width:100%;flex-flow:nowrap;margin-left:0}.widget-preview-image{width:100%}
.ue-logos{display:flex;align-items:center;justify-content:center;width:100%;background:white}
.ue-logos.show{display:flex}.ue-logos img{height:72px;margin:20px 0}.ue-logos .external-link{width:300px;display:block;margin:0 65px}
.ue-logos .external-link a,.ue-logos .external-link p{color:#636770;font-family:'Arial' !important;width:100%;height:100%;font-size:12px;line-height:21px;margin:0;opacity:1;font-weight:400}
.ue-logos .external-link p{text-align:right}.ue-logos .external-link a{text-transform:none;text-decoration:none;transition:.3s all;transition:.3s color}
.ue-logos .external-link a:hover{color:#ee1c24}.ue-logos .external-link a span{display:block;text-align:center}
@media all and (max-width:1122px){.ue-logos{flex-direction:column;text-align:center;height:unset;padding-bottom:15px}
.ue-logos img{order:-1;text-align:center;width:450px;height:auto}.ue-logos .external-link{text-align:center;flex-direction:column;width:80%;margin:0 auto}
.ue-logos .external-link p,.ue-logos .external-link a{text-align:center}}@media all and (max-width:600px){.ue-logos img{width:60%}
}